import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// import GooglePlay from '../images/googleplay.svg';
import Sprout from '../svgs/Sprout';
import Speech from '../svgs/Speech';
import PiggyBank from '../svgs/PiggyBank';
import Package from '../svgs/Package';
import Inventory from '../svgs/Inventory';
import Employee from '../svgs/Employee';

import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import Section from '../components/Section/';
import { Hero } from '../components/Hero/';
import { ImageTextRow } from '../components/ImageTextRow/';
import Advantage, { AdvantageRow } from '../components/Advantage/';
import NewsletterForm from '../components/NewsletterForm';
import { Badges } from '../components/Badges/';
import { AnchorLinks } from '../components/AnchorLinks/';
import '../index.css';
import {
  Header,
  Wrapper,
  ImageContainer,
  Container,
  Title,
  SubTitle,
  DescriptionContainer,
  DescriptionTitle,
  DescriptionText,
} from '../styles/droppoint-werden';

const IndexPage = ({
  data: {
    Hero: HeroImage,
    Hero1,
    Hero4,
    Iphone1,
    Selbstbestimmt,
    Geld,
    Sicher,
    Kommunikation,
    Verwaltung,
  },
}) => {
  return (
    <PageLayout>
      <SEO title="DropPoint werden" />
      <Hero
        fullscreen
        images={[HeroImage, Hero1, Hero4]}
        title={
          <>
            Bleib' daheim
            <br />
            und verdiene Geld.
          </>
        }
        subTitle="Werde DropPoint.">
        <Header>Jetzt die DropFriends-App downloaden.</Header>

        <Badges />
      </Hero>

      <AnchorLinks
        links={[
          { title: 'Allgemein', anchor: '/droppoint-werden#allgemein' },
          { title: 'Vorteile', anchor: '/droppoint-werden#vorteile' },
        ]}
      />

      <Section fullWidth>
        <Wrapper>
          <ImageContainer>
            <div className="w-full md:w-3/4">
              <GatsbyImage
                image={Iphone1.childImageSharp.gatsbyImageData}
                className="w-full h-auto"
                objectFit="contain"
                objectPosition="center center"
                alt=""
              />
            </div>
          </ImageContainer>
          <DescriptionContainer>
            <div className="align-middle" style={{ maxWidth: 576 }}>
              <DescriptionTitle id="allgemein">Funktionale App</DescriptionTitle>
              <DescriptionText>
                Mit nur einer kostenlosen & blitzschnellen Registrierung trittst Du der
                DropFriends-Community bei. Die App ermöglicht Dir die Nutzung als DropPoint und/oder
                als Besteller. Lasse z.B. Deine Online-Einkäufe zu einem DropPoint senden - per
                Antippen. Empfange Sendungen für Deine Community - so einfach wie Freunde begrüßen.
                <br />
                <br />
                Alles in nur einer einzigen App. Du musst weder Deinen Computer zusätzlich
                einschalten, noch eine zweite App auf Dein Handy laden.
              </DescriptionText>
            </div>
          </DescriptionContainer>
        </Wrapper>

        <ImageTextRow rightImageFluid={Selbstbestimmt} position="right" fullWidth>
          <DescriptionTitle>Bleib selbstbestimmt</DescriptionTitle>
          <DescriptionText>
            Passe Deine individuellen Bedürfnissen in der DropFriends-App an.
            <br />
            <br />
            Deine Wohnung ist Dir heilig - uns auch! Deswegen kannst Du selbst bestimmen, wie viele
            Sendungen Du für Deine DropFriends vorhalten möchtest. Ist das Maximum erreicht, dann
            sind weitere Buchungen für Deinen DropPoint erst wieder möglich, wenn die vorgehaltenen
            Sendungen von Dir überreicht worden sind.
            <br />
            <br />
            De-/aktiviere Deinen DropPoint per Fingertipp. Bestimme in welchen Zeiträumen Sendungen
            bei Dir abgeholt werden können. Erhöhe oder reduziere Deine Kapazitäten - blitzschnell
            und kinderleicht.
          </DescriptionText>
        </ImageTextRow>

        <ImageTextRow leftImageFluid={Geld} fullWidth>
          <DescriptionTitle>Geld verdienen</DescriptionTitle>
          <DescriptionText>
            Pro überreichter Sendung erhältst Du als DropPoint eine Vergütung. Sammle positive
            Bewertungen von Deiner Community und steigere so Deine monatliche Auszahlungssumme.
            Biete erweiterte Abholzeiten am Abend oder gar in der Nacht an. Werde der Rockstar
            Deiner Community und generiere so zusätzliche Trinkgelder & Empfehlungen Deiner
            DropFriends.
          </DescriptionText>
        </ImageTextRow>

        <Container>
          <Title>Jetzt die DropFriends-App downloaden.</Title>

          <SubTitle>Erhältlich im App Store und im Google Play Store.</SubTitle>

          <Badges className="my-4" />

          <NewsletterForm />
        </Container>

        <ImageTextRow rightImageFluid={Sicher} position="right" fullWidth>
          <DescriptionTitle>Sicher ist sicher</DescriptionTitle>
          <DescriptionText>
            Vertrauen ist gut, doch Kontrolle ist besser. Entscheide selbst wie Du den Abholer der
            Sendungen identifizieren möchtest:
            <br />
            <br />
            Ist das Gesicht auf dem Foto in der App identisch mit dem des Abholers? Oder ist es Dir
            lieber einen Datenabgleich mit dem Lichtbildausweis des Abholers durchzuführen? Egal,
            wie Du Dich entscheidest, die DropFriends-App unterstützt Dich - sekundenschnell und
            selbsterklärend.
          </DescriptionText>
        </ImageTextRow>

        <ImageTextRow leftImageFluid={Kommunikation} position="left" fullWidth>
          <DescriptionTitle>Vollautomatische Kommunikation</DescriptionTitle>
          <DescriptionText>
            Die DropFriends-App verknüpft die Mitglieder mit ihren jeweiligen Sendungen
            vollautomatisch. So wirst Du umgehend benachrichtigt, wenn man Dir ein Paket zukommen
            lässt.
            <br />
            <br />
            Und sobald der Lieferdienst an Deiner Haustür klingelt, hinterlegst Du in der App nur
            rasch ein Foto der überreichten Sendung. Der Eigentümer des Pakets wird dann über die
            Paketankunft von DropFriends informiert. Fertig.
            <br />
            <br />
            Du kannst sogar als DropPoint mit Hilfe von Fotos beschreiben, wo sich der genaue Zugang
            zu Deinem Wohngebäude befindet, wo Deine Klingel platziert ist oder wo sich der Schalter
            für das Licht versteckt. Die DropFriends-App wird die Paketeigentümer vollautomatisch zu
            einer erfolgreichen Übergabe leiten - ohne, das Du Nachrichten schreiben, telefonieren
            oder Rauchzeichen geben müsstest.
          </DescriptionText>
        </ImageTextRow>

        <ImageTextRow rightImageFluid={Verwaltung} position="right" fullWidth>
          <DescriptionTitle>Intelligente Sendungsverwaltung</DescriptionTitle>
          <DescriptionText>
            Mit Hilfe der smarten Suchfunktion in der App, findest Du im Handumdrehen die Mitglieder
            Deiner Community oder die Sendungen Deiner DropFriends.
            <br />
            <br />
            Übergibt Dir der Lieferdienst ein Paket, dann suche in der DropFriends-App einfach nach
            dem DropFriends-Code - aufgedruckt auf dem Etikett des Paketes. In der App ist bereits
            alles so für Dich zur Dokumentation vorbereitet, dass das Hinterlegen eines Paketbildes
            ausreicht, um den Eigentümer der Sendung über die Annahme zu informieren, das heißt: Ein
            DropPoint zu sein ist sprichwörtlich so einfach wie ein Foto zu schießen.
            <br />
            <br />
            Und vertippst Du Dich einmal im Suchfeld der DropFriends-App, dann nutze die praktischen
            Filterfunktionen. Mit diesen ist es Dir möglich Sendungen mit bestimmten Status
            blitzschnell ausfindig zu machen. So findest Du rasch alle Pakete, welche Du bereits an
            einen Abholer überreichen kannst oder noch auf dem Zustellweg zu Dir sind.
          </DescriptionText>
        </ImageTextRow>
      </Section>

      <AdvantageRow title="Die DropFriends-Vorteile" titleId="vorteile">
        <Advantage
          icon={<PiggyBank className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Haushaltskasse aufbessern"
          title="Nebenverdienst wie für Dich gemacht">
          <p>
            Mitte des Monats kann bedeuten bereits auf Ausgaben achten zu müssen. Ein Studium, das
            Alter oder das Familienleben machen einen zeitaufwendigen Nebenjob aber oft unmöglich.
            Mit DropFriends kannst Du Dich zuhause z.B. um Enkel bzw. Kinder kümmern oder Dich auf
            Deine nächste Prüfung vorbereiten - ohne auf einen Nebenverdienst verzichten zu müssen.
          </p>
        </Advantage>

        <Advantage
          icon={<Package className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Kapazitäten bestimmen"
          title="Nur nach Deinen Spielregeln">
          <p>
            Die DropFriends-App bietet die Möglichkeit Kapazitäten für Paketannahmen Deiner
            individuellen Lebenssituation anzupassen. Bestimme per App, wie viele Sendungen Du
            gleichzeitig vorhalten möchtest. Welche Größe bzw. welches Gewicht kommen für Dich
            maximal in Frage? Deine DropFriends werden vor Buchung über Deine Annahmekriterien
            informiert und gleichen diese mit Ihren Anforderungen ab.
          </p>
        </Advantage>

        <Advantage
          icon={<Speech className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Menschlicher Kontakt verbindet"
          title="Endlich wieder in guter Gesellschaft">
          <p>
            Menschen, die das Haus aufgrund von Alter oder Einschränkungen nur wenig verlassen,
            nehmen durch DropFriends erneut am sozialen Leben teil. Denn: Gespräche, ein Lächeln und
            das Gefühl wieder eine Aufgabe zu haben verbindet und steigert die Lebensqualität.
          </p>
        </Advantage>

        <Advantage
          icon={<Employee className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="App-Unterstützung für Live-Identifizierung"
          title="Sicher ist sicher">
          <p>
            Natürlich möchtest Du gewährleisten, dass die von Dir angenommene Sendung ausschließlich
            dem Eigentümer übergeben wird. Bestimme selbst welche Form der Identifizierung Du an
            Deiner Haustür durchführen möchtest: Fotoabgleich oder Prüfung des Ausweises. Dein
            DropFriend wird bereits vor Buchung über die Prüfungsart informiert. So werden
            Wartezeiten bei der Übergabe vermieden.
          </p>
        </Advantage>

        <Advantage
          icon={<Inventory className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Stresslevel senken & Qualität erhöhen"
          title="Arbeitsbedingungen aktiv verbessern">
          <p>
            Lieferanten sind stets in Eile, denn sie haben nur ein eingeschränktes Zeitfenster für
            die Zustellung von Sendungen. Durch zentrale DropPoints können Zustellungen gebündelt
            werden; das spart Zeit und reduziert manuelle Fehler im Zustellprozess durch die
            Lieferdienste.
          </p>
        </Advantage>

        <Advantage
          icon={<Sprout className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="CO2-Ausstoß pro Paket verringern"
          title="Unserer Umwelt zuliebe">
          <p>
            Wusstest Du, dass das Durchschnittspaket 1kg CO2 ausstößt? Mit DropFriends werden
            mehrere Zustellversuche durch Lieferdienste und deren Benachrichtigungskarten reduziert.
            Deine Wege zu Paketshops und -stationen werden kürzer. Auto und Bahn werden zur Abholung
            somit hinfällig und der CO2-Ausstoß pro Paket wird auf bis zu 277g reduziert.
          </p>
        </Advantage>

        <div className="w-full text-center mx-4 mt-12">
          Noch Fragen?{' '}
          <Link to="/faq/droppoint" className="text-blue-600 hover:underline">
            Die Antworten findest du in unseren FAQs
          </Link>
        </div>
      </AdvantageRow>

      <Container>
        <Title>Jetzt die DropFriends-App downloaden.</Title>

        <SubTitle>Erhältlich im App Store und im Google Play Store.</SubTitle>

        <Badges className="mt-4" />
      </Container>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Hero: file(relativePath: { eq: "annahmestelle/hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero1: file(relativePath: { eq: "collage/oma-mit-herz.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero4: file(relativePath: { eq: "hero/4.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Iphone1: file(relativePath: { eq: "dropfriends-screenshots.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Selbstbestimmt: file(relativePath: { eq: "annahmestelle/selbstbestimmt.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Geld: file(relativePath: { eq: "annahmestelle/white_hat_man.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Sicher: file(relativePath: { eq: "annahmestelle/sicher.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Kommunikation: file(relativePath: { eq: "annahmestelle/kommunikation.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Verwaltung: file(relativePath: { eq: "annahmestelle/verwaltung.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default IndexPage;
