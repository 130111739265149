import React from 'react';

const Employee = ({ className }) => {
  return (
    <svg viewBox="0 0 512.001 512.001" className={className}>
      <path
        fill="currentColor"
        d="M443.338,0c-37.727,0-68.42,30.693-68.42,68.42c0,18.418,7.329,35.148,19.206,47.461l-46.39,45.794
        c-24.744-20.803-56.644-33.355-91.424-33.355c-34.383,0-66.884,12.353-92.202,33.891l-46.572-45.972
        c12.08-12.345,19.547-29.222,19.547-47.817C137.082,30.693,106.389,0,68.661,0S0.241,30.693,0.241,68.42
        c0,37.727,30.693,68.421,68.42,68.421c13.701,0,26.464-4.063,37.176-11.026l47.351,46.742
        c-8.577,9.023-16.055,19.245-22.144,30.52c-1.972,3.653-0.61,8.213,3.042,10.185c3.653,1.974,8.213,0.611,10.185-3.041
        c22.277-41.246,65.206-66.869,112.037-66.869c70.162,0,127.244,57.081,127.244,127.245c0,5.932-0.412,11.895-1.224,17.726
        c-0.572,4.112,2.296,7.909,6.408,8.482c0.352,0.049,0.701,0.072,1.046,0.072c3.691,0,6.911-2.721,7.435-6.481
        c0.907-6.513,1.367-13.175,1.367-19.798c0-38.269-15.192-73.053-39.857-98.651l47.009-46.404c10.799,7.133,23.72,11.3,37.602,11.3
        c37.727,0,68.42-30.693,68.42-68.42C511.759,30.694,481.065,0,443.338,0z M68.661,121.808c-29.438,0-53.387-23.949-53.387-53.387
        c0-29.438,23.949-53.388,53.387-53.388s53.387,23.949,53.387,53.388C122.049,97.858,98.1,121.808,68.661,121.808z
         M443.338,121.808c-29.438,0-53.387-23.949-53.387-53.387c0-29.438,23.949-53.388,53.387-53.388s53.388,23.949,53.388,53.388
        C496.725,97.858,472.776,121.808,443.338,121.808z"
      />

      <path
        fill="currentColor"
        d="M102.64,44.87c-2.935-2.934-7.695-2.934-10.629,0L59.106,77.775L45.311,63.98c-2.935-2.934-7.695-2.934-10.629,0
        c-2.935,2.936-2.935,7.695,0,10.631l19.11,19.11c1.409,1.409,3.321,2.201,5.315,2.201s3.906-0.792,5.315-2.201L102.64,55.5
        C105.576,52.564,105.576,47.805,102.64,44.87z"
      />

      <path
        fill="currentColor"
        d="M477.317,44.87c-2.935-2.934-7.695-2.934-10.629,0l-32.905,32.906L419.988,63.98c-2.935-2.934-7.695-2.934-10.63,0
        c-2.935,2.936-2.935,7.695,0,10.631l19.111,19.11c1.409,1.409,3.321,2.201,5.315,2.201c1.993,0,3.906-0.792,5.315-2.201
        l38.219-38.22C480.252,52.564,480.252,47.805,477.317,44.87z"
      />

      <path
        fill="currentColor"
        d="M102.64,420.027c-2.934-2.932-7.694-2.932-10.629,0.001l-32.905,32.906l-13.795-13.796c-2.935-2.934-7.695-2.934-10.629,0
        c-2.935,2.936-2.935,7.695,0,10.63l19.11,19.11c1.409,1.409,3.321,2.201,5.315,2.201s3.906-0.792,5.315-2.201l38.219-38.22
        C105.576,427.721,105.576,422.963,102.64,420.027z"
      />

      <path
        fill="currentColor"
        d="M443.338,375.159c-17.558,0-33.588,6.654-45.715,17.564l-31.745-31.337c10.805-13.027,19.405-28.106,25.102-44.784
        c1.341-3.929-0.756-8.201-4.684-9.543c-3.927-1.342-8.2,0.757-9.542,4.684c-5.776,16.91-14.889,31.981-26.433,44.62
        c-0.039,0.038-0.081,0.07-0.119,0.109c-0.194,0.197-0.375,0.404-0.543,0.616c-0.84,0.905-1.695,1.795-2.559,2.674
        c-6.882-14.999-17.882-28.464-31.762-38.679c-8.791-6.47-18.556-11.485-28.776-14.891c12.709-9.332,20.979-24.371,20.979-41.311
        v-37.617c0-28.25-22.982-51.232-51.232-51.232h-0.001c-17.656,0-33.254,8.978-42.47,22.607
        c-5.529,8.178-8.761,18.031-8.761,28.624v37.617c0,16.94,8.27,31.979,20.979,41.311c-10.219,3.406-19.985,8.421-28.776,14.891
        c-13.856,10.197-24.841,23.637-31.725,38.603c-22.554-22.971-36.49-54.432-36.49-89.09c0-12.825,1.9-25.479,5.647-37.608
        c1.226-3.967-0.997-8.175-4.963-9.401c-3.97-1.225-8.175,0.997-9.401,4.963c-4.192,13.57-6.317,27.715-6.317,42.046
        c0,34.116,12.075,65.464,32.17,90.002l-32.259,31.748c-12.074-10.684-27.928-17.188-45.281-17.188
        c-37.727,0-68.42,30.693-68.42,68.42c0,37.728,30.693,68.421,68.42,68.421c37.727,0,68.42-30.693,68.42-68.421
        c0-14.967-4.844-28.816-13.027-40.091l32.277-31.766c2.154,2.129,4.372,4.192,6.656,6.181c0.202,0.221,0.409,0.439,0.639,0.637
        c11.2,9.626,23.754,17.369,37.16,23.049c0.074,0.032,0.147,0.065,0.223,0.096c0.336,0.141,0.673,0.275,1.01,0.413
        c12.015,4.979,24.855,8.354,38.252,9.865c0.108,0.012,0.216,0.025,0.325,0.037c0.861,0.095,1.724,0.181,2.59,0.261
        c0.303,0.028,0.605,0.055,0.909,0.081c0.736,0.063,1.473,0.122,2.213,0.173c0.461,0.033,0.924,0.06,1.386,0.088
        c0.62,0.037,1.242,0.075,1.864,0.104c0.639,0.03,1.28,0.051,1.92,0.073c0.473,0.016,0.945,0.036,1.419,0.048
        c1.136,0.027,2.274,0.043,3.413,0.043c1.731,0,3.457-0.04,5.18-0.102c0.259-0.009,0.517-0.015,0.776-0.026
        c1.58-0.066,3.157-0.163,4.73-0.282c0.408-0.03,0.816-0.062,1.224-0.096c1.471-0.123,2.94-0.269,4.405-0.437
        c0.51-0.059,1.018-0.124,1.526-0.188c1.28-0.162,2.558-0.337,3.831-0.533c0.682-0.104,1.36-0.219,2.039-0.335
        c0.949-0.16,1.895-0.33,2.839-0.51c0.961-0.182,1.918-0.375,2.873-0.576c0.861-0.182,1.72-0.37,2.576-0.567
        c0.787-0.181,1.572-0.366,2.355-0.56c1.077-0.268,2.152-0.549,3.222-0.842c0.851-0.233,1.698-0.476,2.543-0.725
        c0.542-0.159,1.08-0.326,1.621-0.491c1.362-0.418,2.716-0.854,4.061-1.311c0.471-0.16,0.941-0.323,1.41-0.488
        c1.412-0.497,2.814-1.016,4.208-1.556c0.432-0.167,0.865-0.334,1.295-0.505c2.699-1.076,5.36-2.233,7.98-3.469
        c0.418-0.197,0.834-0.401,1.25-0.601c1.353-0.654,2.694-1.328,4.024-2.023c0.422-0.221,0.844-0.444,1.265-0.669
        c1.354-0.726,2.694-1.474,4.023-2.243c0.364-0.21,0.729-0.418,1.09-0.632c2.561-1.511,5.07-3.103,7.528-4.77
        c0.218-0.148,0.436-0.299,0.653-0.449c1.547-1.061,3.073-2.153,4.577-3.275c0.076-0.057,0.152-0.113,0.228-0.169
        c4.927-3.69,9.629-7.681,14.047-11.987l31.919,31.508c-7.977,11.189-12.691,24.859-12.691,39.617
        c0,37.728,30.693,68.421,68.42,68.421s68.42-30.693,68.42-68.421C511.758,405.852,481.065,375.159,443.338,375.159z
         M68.661,496.967c-29.438,0-53.387-23.949-53.387-53.388c0-29.437,23.949-53.387,53.387-53.387s53.387,23.95,53.387,53.387
        C122.049,473.017,98.1,496.967,68.661,496.967z M220.11,227.265c0-19.961,16.238-36.199,36.199-36.199
        c19.961,0,36.198,16.238,36.198,36.199v10.223c-14.464-4.164-20.481-19.17-20.756-19.878c-1.043-2.751-3.595-4.641-6.531-4.836
        c-2.934-0.189-5.716,1.341-7.112,3.931c-0.108,0.201-10.343,18.563-37.997,21.468V227.265z M228.558,392.809l2.788,2.552
        c-7.667-1.534-15.166-3.767-22.402-6.669c-0.682-0.275-1.365-0.549-2.041-0.836c-0.123-0.052-0.245-0.106-0.368-0.159
        c-10.525-4.491-20.323-10.362-29.173-17.381c8.951-23.15,29.054-41.506,52.904-49.672l7.6,14.763l-11.558,50.17
        C225.7,388.217,226.56,390.98,228.558,392.809z M256.309,397.829l-14.366-13.149l9.848-42.75h9.037l9.849,42.75L256.309,397.829z
         M245.281,316.966c0.371-0.055,0.739-0.118,1.108-0.169c0.197-0.027,0.394-0.053,0.591-0.078c0.787-0.102,1.576-0.189,2.366-0.27
        c0.278-0.028,0.555-0.057,0.833-0.082c0.749-0.068,1.499-0.12,2.25-0.167c0.281-0.017,0.56-0.04,0.841-0.055
        c0.599-0.031,1.201-0.044,1.801-0.063c0.412,0.01,0.822,0.031,1.236,0.031c0.414,0,0.824-0.021,1.236-0.031
        c0.6,0.018,1.202,0.031,1.801,0.063c0.281,0.015,0.561,0.038,0.843,0.055c0.751,0.047,1.501,0.1,2.249,0.167
        c0.278,0.025,0.555,0.055,0.833,0.082c0.791,0.079,1.58,0.167,2.367,0.27c0.196,0.025,0.394,0.051,0.59,0.078
        c0.371,0.051,0.739,0.114,1.108,0.169l-5.111,9.931h-11.831L245.281,316.966z M257.725,301.045
        c-0.438-0.012-0.876-0.032-1.314-0.038c-0.068-0.002-0.137-0.002-0.205,0c-0.438,0.006-0.876,0.026-1.314,0.038
        c-19.305-0.748-34.782-16.677-34.782-36.163v-11.609c22.04-1.871,35.893-11.749,43.558-19.562
        c5.349,7.553,14.58,16.707,28.839,19.209v11.962C292.507,284.367,277.03,300.297,257.725,301.045z M328.861,375.13
        c-0.036,0.025-0.073,0.05-0.109,0.075c-1.342,0.929-2.701,1.83-4.074,2.705c-0.218,0.139-0.437,0.277-0.656,0.415
        c-1.208,0.759-2.427,1.496-3.658,2.213c-0.285,0.166-0.57,0.333-0.856,0.496c-1.19,0.68-2.39,1.339-3.599,1.98
        c-0.309,0.163-0.615,0.329-0.925,0.49c-1.343,0.698-2.699,1.369-4.065,2.018c-0.172,0.082-0.344,0.167-0.517,0.249
        c-0.757,0.355-1.516,0.702-2.28,1.042c-0.321,0.142-0.643,0.28-0.965,0.42c-1.235,0.538-2.475,1.059-3.726,1.557
        c-0.384,0.153-0.771,0.302-1.156,0.451c-1.191,0.461-2.386,0.906-3.59,1.331c-0.428,0.151-0.857,0.299-1.287,0.445
        c-1.151,0.393-2.306,0.768-3.468,1.127c-0.491,0.152-0.983,0.302-1.476,0.448c-1.056,0.313-2.118,0.61-3.182,0.896
        c-0.613,0.164-1.227,0.327-1.843,0.482c-0.768,0.193-1.535,0.382-2.307,0.561c-0.927,0.215-1.857,0.42-2.79,0.614
        c-0.353,0.074-0.708,0.143-1.061,0.214l2.788-2.552c1.998-1.828,2.858-4.592,2.25-7.232l-11.557-50.17l7.599-14.763
        c23.868,8.172,43.986,26.55,52.926,49.726C333.184,372.028,331.045,373.615,328.861,375.13z M443.338,496.967
        c-29.438,0-53.387-23.949-53.387-53.388c0-29.437,23.949-53.387,53.387-53.387s53.388,23.95,53.388,53.387
        C496.725,473.017,472.776,496.967,443.338,496.967z"
      />

      <path
        fill="currentColor"
        d="M477.316,420.027c-2.933-2.932-7.693-2.932-10.628,0.001l-32.905,32.906l-13.795-13.796c-2.935-2.934-7.695-2.934-10.63,0
        c-2.935,2.936-2.935,7.695,0,10.63l19.11,19.11c1.409,1.409,3.321,2.201,5.315,2.201s3.906-0.792,5.315-2.201l38.219-38.22
        C480.251,427.721,480.251,422.963,477.316,420.027z"
      />
    </svg>
  );
};

export default Employee;
