import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Anchor, Container, Main, Section, Title } from './styles';

export const AnchorLinks = ({ links = [], title = '' }) => {
  const [isFixed, setIsFixed] = React.useState(false);
  const [top, setTop] = React.useState(0);

  React.useLayoutEffect(() => {
    const anchorLinks = document.getElementById('anchorLinks');
    const navigation = document.getElementById('navigation');
    const topOfContainer = anchorLinks.offsetTop;
    const heightOfContainer = anchorLinks.offsetHeight;
    const heightOfNavigation = navigation.offsetHeight;
    setTop(heightOfNavigation);

    const handleScroll = e => {
      if (e.target.documentElement.scrollTop > topOfContainer + heightOfContainer) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  });

  return (
    <Section id="anchorLinks">
      <Main style={{ top }} isFixed={isFixed}>
        <Container>
          {title ? <Title>{title}</Title> : null}
          {links.map(({ anchor, title }) => (
            <Anchor key={anchor}>
              <AnchorLink to={anchor} title={title} stripHash className="">
                <span>{title}</span>
              </AnchorLink>
            </Anchor>
          ))}
        </Container>
      </Main>
    </Section>
  );
};
