import React from 'react';

const Speech = ({ className }) => {
  return (
    <svg viewBox="0 0 478.579 478.579" className={className}>
      <path
        fill="currentColor"
        d="M128.83,332.267c25.273-17.827,31.31-52.766,13.483-78.04s-52.766-31.31-78.04-13.483
			C39,258.57,32.963,293.51,50.79,318.783c3.052,4.326,6.701,8.198,10.84,11.499c-30.866,10.306-50.982,40.035-49.072,72.52l0.008,0
			l4,68.248c0.25,4.233,3.76,7.535,8,7.528h136c4.24,0.007,7.75-3.295,8-7.528l4-68.248
			C174.327,372.418,156.829,344.198,128.83,332.267z M56.566,286.579c0-22.091,17.909-40,40-40c22.091,0,40,17.909,40,40
			c0,22.091-17.909,40-40,40C74.485,326.552,56.592,308.659,56.566,286.579z M156.598,401.867l-3.576,60.712H32.102l-3.568-60.712
			c-1.816-30.875,21.741-57.375,52.615-59.191c1.095-0.064,2.192-0.097,3.289-0.097h16.248
			c30.928-0.005,56.004,25.063,56.009,55.991C156.694,399.67,156.662,400.769,156.598,401.867z"
      />

      <path
        fill="currentColor"
        d="M400.83,332.267c25.273-17.827,31.31-52.766,13.483-78.04s-52.766-31.31-78.04-13.483
			c-25.273,17.827-31.31,52.766-13.483,78.04c3.052,4.326,6.701,8.198,10.84,11.499c-30.866,10.306-50.982,40.035-49.072,72.52
			l0.008,0l4,68.248c0.25,4.233,3.76,7.535,8,7.528h136c4.24,0.007,7.75-3.295,8-7.528l4-68.248
			C446.327,372.418,428.829,344.198,400.83,332.267z M328.566,286.579c0-22.091,17.909-40,40-40c22.091,0,40,17.909,40,40
			c0,22.091-17.909,40-40,40C346.485,326.552,328.592,308.659,328.566,286.579z M428.598,401.867l-3.576,60.712h-120.92
			l-3.568-60.712c-1.816-30.875,21.741-57.375,52.615-59.191c1.095-0.064,2.192-0.097,3.289-0.097h16.248
			c30.928-0.005,56.004,25.063,56.009,55.991C428.694,399.67,428.662,400.769,428.598,401.867z"
      />

      <path
        fill="currentColor"
        d="M429.782,75.747l-61.256-5.6l2.264-36.192c0.562-9.116-2.79-18.038-9.216-24.528c-6.476-6.444-15.372-9.852-24.496-9.384
			l-251.776,13.6v0.016c-13.772,0.745-25.516,10.23-29.144,23.536l-11.72,42.984c-4.656,17.049,5.39,34.644,22.439,39.3
			c2.061,0.563,4.173,0.918,6.305,1.06l27.256,1.792v28.248c0.026,22.08,17.92,39.974,40,40h10.84l-2.84,39.432
			c-0.266,3.761,2.13,7.198,5.752,8.248c3.621,1.051,7.484-0.569,9.272-3.888l23.6-43.792h76.728l29.704,52
			c2.193,3.836,7.08,5.168,10.915,2.975c2.141-1.224,3.598-3.366,3.949-5.807l6.96-48.736l105.4,5.848
			c21.705,1.109,40.362-15.234,42.12-36.896l3.2-41.296C467.628,96.883,451.525,77.82,429.782,75.747z M152.438,174.579h-12
			c-13.255,0-24-10.745-24-24v-27.2l39.448,2.632L152.438,174.579z M167.078,193.971l5.36-74.824
			c0.323-4.406-2.988-8.24-7.395-8.563c-0.026-0.002-0.052-0.004-0.077-0.005l-90.72-6.048c-8.818-0.572-15.502-8.185-14.93-17.003
			c0.07-1.073,0.247-2.136,0.53-3.173l11.792-42.952c1.814-6.654,7.689-11.397,14.576-11.768L337.91,16.027
			c4.573-0.327,9.054,1.4,12.224,4.712c3.245,3.216,4.938,7.681,4.64,12.24l-4.8,77.296c-0.604,8.75-8.095,15.41-16.856,14.984
			l-120.232-6.68c-3.106-0.21-6.043,1.434-7.488,4.192L167.078,193.971z M450.07,117.427l-3.2,41.304
			c-1.043,12.995-12.236,22.802-25.256,22.128l-112.728-6.28c-4.136-0.21-7.756,2.752-8.368,6.848l-4.704,32.928l-20.432-35.776
			c-1.432-2.48-4.08-4.006-6.944-4h-72.76l21.392-39.728l115.128,6.4c17.552,0.948,32.592-12.418,33.712-29.96l1.6-25.128
			l60.8,5.528C441.356,92.922,451.024,104.357,450.07,117.427z"
      />
    </svg>
  );
};

export default Speech;
