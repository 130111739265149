import React from 'react';

const PiggyBank = ({ className }) => {
  return (
    <svg viewBox="0 0 512 512" className={className}>
      <path
        fill="currentColor"
        d="M422.503,270.613c-4.115,0-7.451,3.336-7.451,7.451c0,5.217-4.244,9.461-9.461,9.461c-5.216,0-9.461-4.244-9.461-9.461
        c0-4.116-3.336-7.451-7.451-7.451s-7.451,3.336-7.451,7.451c0,13.434,10.93,24.363,24.363,24.363
        c13.433,0,24.363-10.93,24.363-24.363C429.954,273.947,426.618,270.613,422.503,270.613z"
      />

      <path
        fill="currentColor"
        d="M492.444,271.83l-20.972-6.718c-2.686-0.861-4.803-3.005-5.662-5.736c-4.42-14.053-11.09-27.246-19.825-39.214
        c-3.981-5.455-8.408-10.664-13.244-15.623c3.094-13.573,4.776-28.934,4.558-42.205c-0.312-18.985-4.106-30.12-11.598-34.044
        c-4.035-2.116-16.315-8.544-82.782,21.882c-1.574,0.72-3.044,1.607-4.405,2.623c-4.481-1.204-9.061-2.318-13.669-3.318
        c-0.555-0.121-1.127-0.228-1.686-0.346c5.386-11.766,8.531-24.545,9.122-37.737c0.184-4.112-2.999-7.594-7.111-7.777
        c-4.101-0.204-7.594,2.998-7.777,7.11c-0.971,21.691-9.969,42.1-25.335,57.467c-9.181,9.181-19.931,15.85-31.388,20.05
        c-19.869-2.788-40.107-2.788-59.976,0c-11.458-4.2-22.207-10.869-31.388-20.05c-16.394-16.394-25.423-38.191-25.423-61.376
        c0-23.186,9.029-44.982,25.423-61.376c16.394-16.395,38.192-25.424,61.376-25.424c23.184,0,44.982,9.029,61.376,25.424
        c6.588,6.588,12.032,14.106,16.181,22.343c1.851,3.676,6.332,5.156,10.007,3.304c3.676-1.85,5.155-6.331,3.304-10.006
        c-4.863-9.659-11.24-18.466-18.953-26.18c-19.209-19.209-44.749-29.788-71.914-29.788c-27.165,0-52.705,10.579-71.914,29.788
        c-19.209,19.21-29.788,44.749-29.788,71.914s10.579,52.705,29.788,71.914c5.541,5.541,11.569,10.306,17.945,14.298
        c0.002,0,0.004-0.001,0.006-0.001c-4.633,1.204-9.231,2.556-13.778,4.076c-3.903,1.305-6.009,5.526-4.704,9.43
        c1.304,3.903,5.527,6.009,9.429,4.705c40.636-13.585,85.396-13.585,126.032,0c0.784,0.262,1.58,0.386,2.363,0.386
        c3.117,0,6.023-1.972,7.066-5.091c1.305-3.903-0.801-8.125-4.704-9.43c-4.547-1.52-9.145-2.873-13.778-4.076
        c0.002,0,0.004,0.001,0.006,0.001c6.377-3.992,12.404-8.757,17.945-14.298c4.902-4.902,9.229-10.255,12.986-15.944
        c2.054,0.399,4.093,0.817,6.102,1.253c2.676,0.581,5.34,1.205,7.98,1.86c-0.083,0.324-0.168,0.647-0.237,0.976
        c-3.634,17.245-3.398,32.497,0.701,45.33c3.684,11.535,10.333,20.88,19.76,27.777c11.111,8.128,24.525,11.639,36.676,11.639
        c9.145,0,17.576-1.99,23.768-5.497c7.004-3.967,12.872-12.326,17.517-24.876c2.147,2.506,4.182,5.073,6.1,7.701
        c7.777,10.654,13.714,22.396,17.646,34.898c2.309,7.343,8.041,13.121,15.332,15.458l20.972,6.718
        c5.503,1.763,9.2,6.828,9.2,12.606v34.775c0,5.778-3.697,10.843-9.2,12.606l-33.789,10.823
        c-5.406,1.733-9.981,5.448-12.882,10.462c-13.275,22.942-34.146,41.927-62.037,56.428c-3.222,1.677-5.454,4.676-6.126,8.23
        l-10.822,57.303c-0.299,1.579-1.682,2.724-3.289,2.724h-35.187c-1.607,0-2.99-1.146-3.289-2.724l-5.994-31.669
        c-1.18-6.231-7.024-10.37-13.314-9.417c-15.157,2.292-30.775,3.453-46.418,3.453c-11.638,0-23.127-0.649-34.15-1.93
        c-6.095-0.701-11.773,3.454-12.912,9.472l-5.696,30.091c-0.299,1.579-1.682,2.724-3.289,2.724h-35.187
        c-1.607,0-2.99-1.146-3.293-2.748l-10.42-54.122c-0.671-3.487-2.861-6.452-6.012-8.135C96.25,401.595,71.12,359.191,71.12,304.351
        c0-27.708,6.062-52.209,18.017-72.825c11.156-19.239,27.45-35.146,48.429-47.281c3.562-2.061,4.779-6.619,2.719-10.182
        c-2.06-3.562-6.618-4.777-10.181-2.719C89.425,194.876,64.89,231.18,58.135,277.29v-0.001c-0.717-0.065-1.435-0.127-2.15-0.201
        c0.13-4.975-0.77-9.995-2.73-14.867c-4.323-10.746-13.203-18.502-22.624-19.759c-7.424-0.994-14.489,2.158-19.392,8.636
        c-7.109,9.394-5.95,16.828-3.727,21.41c3.753,7.733,13.4,13.203,29.409,16.642c-0.44,0.659-0.924,1.321-1.454,1.986
        c-9.459,11.863-20.603,14.486-27.839,14.733C3.392,306.012,0,309.413,0,313.652v0.036c0,4.181,3.299,7.591,7.476,7.782
        c0.535,0.025,1.094,0.038,1.676,0.038c9.156,0,23.851-3.377,37.969-21.08c2.229-2.794,4.045-5.742,5.441-8.788
        c1.406,0.159,2.757,0.295,4.051,0.415c0-0.003,0-0.005,0.001-0.008c-0.254,4.038-0.395,8.135-0.395,12.304
        c0,31.028,7.397,58.693,21.984,82.23c13.45,21.7,32.707,39.468,57.255,52.83l10.13,52.616c1.629,8.608,9.17,14.856,17.931,14.856
        h35.187c8.761,0,16.302-6.248,17.931-14.856l5.15-27.208c10.696,1.133,21.77,1.707,32.965,1.707c15.36,0,30.702-1.07,45.654-3.182
        l5.429,28.685c1.63,8.608,9.171,14.855,17.931,14.855h35.187c8.761,0,16.302-6.248,17.932-14.859l10.537-55.791
        c29.768-15.741,52.206-36.419,66.704-61.475c1.045-1.806,2.654-3.131,4.53-3.732l33.788-10.823
        C504.141,356.455,512,345.686,512,333.403v-34.775C512,286.346,504.141,275.577,492.444,271.83z M421.278,183.273
        c-2.868,24.526-10.561,45.506-18.291,49.884c-8.786,4.977-29.071,5.938-44.301-5.203c-15.248-11.154-20.323-31.212-14.678-58.006
        c0-0.001,0-0.001,0-0.001c0.567-2.691,2.479-5.019,5.114-6.225c37.304-17.076,58.811-22.661,66.801-22.661
        c1.206,0,2.104,0.127,2.702,0.361C421.025,143.98,424.173,158.521,421.278,183.273z M20.921,266.001
        c-0.771-1.59,1.049-4.385,2.203-5.911c1.829-2.416,3.514-2.91,4.787-2.91c0.269,0,0.52,0.022,0.75,0.053
        c3.426,0.457,8.226,4.227,10.769,10.548c0.599,1.488,1.377,3.953,1.559,7.027C30.674,272.671,22.66,269.59,20.921,266.001z"
      />

      <path
        fill="currentColor"
        d="M237.918,98.277V71.613c9.195,0.657,12.611,4.86,15.763,4.86c3.941,0,5.78-4.991,5.78-7.487
        c0-6.437-12.611-9.195-21.543-9.458V55.98c0-1.576-1.971-3.021-3.941-3.021c-2.234,0-3.81,1.445-3.81,3.021v3.809
        c-12.479,1.314-24.958,7.882-24.958,24.433c0,16.814,13.136,21.543,24.958,25.746v30.869
        c-13.399-1.051-16.945-10.246-21.28-10.246c-3.284,0-6.042,4.335-6.042,7.488c0,6.436,11.034,15.237,27.322,15.5h0v4.072
        c0,1.576,1.577,3.021,3.81,3.021c1.971,0,3.941-1.445,3.941-3.021v-4.466c14.187-1.971,23.907-10.903,23.907-27.06
        C261.825,108.261,249.346,102.48,237.918,98.277z M230.955,95.782c-6.962-2.627-12.61-5.385-12.61-12.872
        c0-6.831,5.254-10.115,12.61-11.034V95.782z M237.129,140.574v-27.716c6.436,2.758,11.559,6.436,11.559,14.711
        C248.688,135.058,244.222,139.261,237.129,140.574z"
      />
    </svg>
  );
};

export default PiggyBank;
