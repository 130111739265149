import React from 'react';

const Inventory = ({ className }) => {
  return (
    <svg viewBox="0 0 64 64" className={className}>
      <path fill="currentColor" d="m33 63h30v-24h-30zm12-22h6v2h-6zm-10 0h8v4h10v-4h8v20h-26z" />
      <path fill="currentColor" d="m57 57h2v2h-2z" />
      <path fill="currentColor" d="m53 57h2v2h-2z" />
      <path fill="currentColor" d="m49 57h2v2h-2z" />
      <path
        fill="currentColor"
        d="m61 21h-2v-20h-26v20h-2v-20h-26v20h-2c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h5.263c-.166.641-.263 1.308-.263 2 0 2.78 1.427 5.231 3.585 6.665-6.123 1.89-10.585 7.6-10.585 14.335 0 2.045 1.237 3.802 3 4.576v8.424h27v-13c0-6.735-4.462-12.445-10.585-14.335 2.158-1.434 3.585-3.885 3.585-6.665 0-.692-.097-1.359-.263-2h37.263c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2zm-18-18h6v2h-6zm-8 0h6v4h10v-4h6v18h-6v-4h-10v4h-6zm14 18h-6v-2h6zm-34-18h6v2h-6zm-8 0h6v4h10v-4h6v18h-6v-4h-10v4h-6zm8 18v-2h6v2zm-12 4v-2h7.726c-.65.572-1.206 1.247-1.644 2zm3 30h4v6h-4zm9.5-7h-3.5v-5h7v5h5v13h-12v-6h3.5c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5zm5.5-3.586 1.586 1.586h-1.586zm8 5.586v11h-3v-14.414l-5.586-5.586h-10.414v7h-3v2h8.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5h-9.5c-1.654 0-3-1.346-3-3 0-7.168 5.832-13 13-13s13 5.832 13 13zm-13-15c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm45-10h-38.082c-.437-.753-.994-1.428-1.644-2h39.726z"
      />
      <path fill="currentColor" d="m49 53h10v2h-10z" />
    </svg>
  );
};

export default Inventory;
