import tw from 'twin.macro';

export const Header = tw.div`font-display text-base tracking-wide`;

export const Wrapper = tw.div`w-full flex flex-wrap items-center`;

export const ImageContainer = tw.div`flex justify-center items-center w-full md:w-1/2 h-64 md:h-full bg-center bg-contain bg-no-repeat`;

export const Container = tw.section`w-full mx-auto text-center py-24 px-8 md:px-16 bg-primaryLight`;

export const Title = tw.h2`font-display w-full my-2 text-2xl md:text-3xl lg:text-5xl font-bold leading-tight text-center`;

export const SubTitle = tw.h3`font-display text-xl lg:text-3xl leading-tight`;

export const DescriptionContainer = tw.div`w-full md:w-1/2 px-6 md:px-8 lg:px-16 py-16 md:py-32 flex justify-start`;

export const DescriptionTitle = tw.h3`font-display text-2xl md:text-3xl text-gray-800 font-bold leading-none md:mt-0 mb-4`;

export const DescriptionText = tw.p`text-gray-700 text-lg`;
