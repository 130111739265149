import React from 'react';

const Package = ({ className }) => {
  return (
    <svg viewBox="0 0 512 512" className={className}>
      <path
        fill="currentColor"
        d="M76.8,375.467c-32.939,0-59.733,26.795-59.733,59.733s26.795,59.733,59.733,59.733s59.733-26.795,59.733-59.733
			S109.739,375.467,76.8,375.467z M76.8,477.867c-23.526,0-42.667-19.14-42.667-42.667c0-23.526,19.14-42.667,42.667-42.667
			c23.526,0,42.667,19.14,42.667,42.667C119.467,458.726,100.326,477.867,76.8,477.867z"
      />

      <path
        fill="currentColor"
        d="M93.867,409.6H59.733c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533h34.133
			c4.71,0,8.533-3.823,8.533-8.533v-34.133C102.4,413.423,98.577,409.6,93.867,409.6z M85.333,443.733H68.267v-17.067h17.067
			V443.733z"
      />

      <path
        fill="currentColor"
        d="M435.2,375.467c-32.939,0-59.733,26.795-59.733,59.733s26.795,59.733,59.733,59.733s59.733-26.795,59.733-59.733
			S468.139,375.467,435.2,375.467z M435.2,477.867c-23.526,0-42.667-19.14-42.667-42.667c0-23.526,19.14-42.667,42.667-42.667
			c23.526,0,42.667,19.14,42.667,42.667C477.867,458.726,458.726,477.867,435.2,477.867z"
      />

      <path
        fill="currentColor"
        d="M452.267,409.6h-34.133c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533h34.133
			c4.71,0,8.533-3.823,8.533-8.533v-34.133C460.8,413.423,456.977,409.6,452.267,409.6z M443.733,443.733h-17.067v-17.067h17.067
			V443.733z"
      />

      <path
        fill="currentColor"
        d="M503.467,341.333H8.533c-4.71,0-8.533,3.823-8.533,8.533V384c0,4.71,3.823,8.533,8.533,8.533h494.933
			c4.71,0,8.533-3.823,8.533-8.533v-34.133C512,345.156,508.177,341.333,503.467,341.333z M494.933,375.467H17.067V358.4h477.867
			V375.467z"
      />

      <path
        fill="currentColor"
        d="M418.133,170.667h-68.267c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,4.71,3.823,8.533,8.533,8.533h68.267
			c4.71,0,8.533-3.823,8.533-8.533v-51.2C426.667,174.49,422.844,170.667,418.133,170.667z M409.6,221.867h-51.2v-34.133h51.2
			V221.867z"
      />

      <path
        fill="currentColor"
        d="M477.867,170.667H290.133c-4.71,0-8.533,3.823-8.533,8.533v170.667c0,4.71,3.823,8.533,8.533,8.533h187.733
			c4.71,0,8.533-3.823,8.533-8.533V179.2C486.4,174.49,482.577,170.667,477.867,170.667z M469.333,341.333H298.667v-153.6h170.667
			V341.333z"
      />

      <path
        fill="currentColor"
        d="M358.4,307.2h-25.6v-25.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v34.133
			c0,4.71,3.823,8.533,8.533,8.533H358.4c4.71,0,8.533-3.823,8.533-8.533S363.11,307.2,358.4,307.2z"
      />

      <path
        fill="currentColor"
        d="M443.733,273.067c-4.71,0-8.533,3.823-8.533,8.533v25.6h-25.6c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533
			h34.133c4.71,0,8.533-3.823,8.533-8.533V281.6C452.267,276.89,448.444,273.067,443.733,273.067z"
      />

      <path
        fill="currentColor"
        d="M230.4,136.533h-68.267c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,4.71,3.823,8.533,8.533,8.533H230.4
			c4.71,0,8.533-3.823,8.533-8.533v-51.2C238.933,140.356,235.11,136.533,230.4,136.533z M221.867,187.733h-51.2V153.6h51.2V187.733
			z"
      />

      <path
        fill="currentColor"
        d="M290.133,136.533H102.4c-4.71,0-8.533,3.823-8.533,8.533v204.8c0,4.71,3.823,8.533,8.533,8.533h187.733
			c4.71,0,8.533-3.823,8.533-8.533v-204.8C298.667,140.356,294.844,136.533,290.133,136.533z M281.6,341.333H110.933V153.6H281.6
			V341.333z"
      />

      <path
        fill="currentColor"
        d="M324.267,34.133H256c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,4.71,3.823,8.533,8.533,8.533h68.267
			c4.71,0,8.533-3.823,8.533-8.533v-51.2C332.8,37.956,328.977,34.133,324.267,34.133z M315.733,85.333h-51.2V51.2h51.2V85.333z"
      />

      <path
        fill="currentColor"
        d="M76.015,94.822l-28.535-61.85c-3.567-9.515-12.792-15.906-22.955-15.906c-8.038,0-15.565,3.942-20.147,10.547
			c-4.574,6.613-5.623,15.053-2.509,23.27L25.6,100.335v249.532c0,4.71,3.823,8.533,8.533,8.533h34.133
			c4.71,0,8.533-3.823,8.533-8.533V98.389C76.8,97.161,76.535,95.94,76.015,94.822z M59.733,341.333H42.667V98.389
			c0-1.28-0.29-2.534-0.845-3.695l-24.269-50.5c-0.87-2.321-0.563-4.821,0.853-6.861c3.174-4.574,10.948-4.036,13.329,2.21
			l27.998,60.723V341.333z"
      />

      <path
        fill="currentColor"
        d="M221.867,221.867h-51.2c-4.71,0-8.533,3.823-8.533,8.533V256c0,18.825,15.309,34.133,34.133,34.133
			S230.4,274.825,230.4,256v-25.6C230.4,225.69,226.577,221.867,221.867,221.867z M213.333,256c0,9.412-7.654,17.067-17.067,17.067
			c-9.412,0-17.067-7.654-17.067-17.067v-17.067h34.133V256z"
      />

      <path
        fill="currentColor"
        d="M196.267,273.067c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V281.6
			C204.8,276.89,200.977,273.067,196.267,273.067z"
      />

      <path
        fill="currentColor"
        d="M221.867,307.2h-51.2c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h51.2c4.71,0,8.533-3.823,8.533-8.533
			S226.577,307.2,221.867,307.2z"
      />

      <path
        fill="currentColor"
        d="M384,34.133H196.267c-4.71,0-8.533,3.823-8.533,8.533v102.4c0,4.71,3.823,8.533,8.533,8.533H281.6v25.6
			c0,4.71,3.823,8.533,8.533,8.533H384c4.71,0,8.533-3.823,8.533-8.533V42.667C392.533,37.956,388.71,34.133,384,34.133z
			 M375.467,170.667h-76.8v-25.6c0-4.71-3.823-8.533-8.533-8.533H204.8V51.2h170.667V170.667z"
      />
    </svg>
  );
};

export default Package;
