import tw, { styled } from 'twin.macro';

export const ColoredText = tw.div`text-green-300`;

export const Title = tw.div`text-xs font-semibold md:text-base pr-4 md:pr-8 flex justify-center items-center`;

export const Section = tw.div`h-12 bg-white pb-12`;

export const Container = tw.div`max-w-lg mx-auto w-full px-4 md:px-8 py-4 flex items-center justify-center`;

export const Anchor = tw.div`flex justify-center items-center px-1 md:px-2`;

export const Main = styled.div(({ isFixed }) => [
  tw`h-12 bg-white flex justify-center z-40`,
  isFixed ? tw`fixed w-full shadow-lg` : '',
]);
